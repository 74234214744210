.input-container[data-v-9bee91af] {
  display: inline-flex;
}
.input[data-v-9bee91af] {
  margin-bottom: var(--9bee91af-margin);
  min-width: 150px;
  flex: 1;
}
.projectLink .el-select-dropdown__item[data-v-9bee91af] {
  height: 59px !important;
}
.projectLink .el-select-dropdown__item > p[data-v-9bee91af] {
  display: flex;
  flex-direction: column;
}
.projectLink .el-select-dropdown__item > p > span[data-v-9bee91af] {
  font-size: 12px;
  line-height: 1em;
}
