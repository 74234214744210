.table[data-v-d882c9ff] {
  width: 100%;
  height: 100%;
}
.table[data-v-d882c9ff] .customCell {
  background-color: red;
  color: white;
}
[data-v-d882c9ff] .ht_clone_left {
  z-index: 1;
}
[data-v-d882c9ff] .ht_clone_top {
  z-index: 1;
}
[data-v-d882c9ff] .ht_clone_top_inline_start_corner.ht_clone_top_left_corner {
  z-index: 1;
}
[data-v-d882c9ff] .ht_master {
  padding-bottom: 15px;
}
[data-v-d882c9ff] .ht_clone_inline_start.ht_clone_left.handsontable {
  display: inline;
}
