.input-container[data-v-65dafe9c] {
  display: inline-flex;
}
.input[data-v-65dafe9c] {
  margin-bottom: var(--65dafe9c-margin);
  min-width: 150px;
  flex: 1;
}
.projectLink .el-select-dropdown__item > p[data-v-65dafe9c] {
  display: flex;
  flex-direction: column;
}
.projectLink .el-select-dropdown__item > p > span[data-v-65dafe9c] {
  font-size: 12px;
  line-height: 1em;
}
